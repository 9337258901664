import { render, staticRenderFns } from "./certificate.html?vue&type=template&id=3e4d357e&scoped=true&external"
import script from "./certificate.vue?vue&type=script&lang=js"
export * from "./certificate.vue?vue&type=script&lang=js"
import style0 from "./certificate.css?vue&type=style&index=0&id=3e4d357e&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e4d357e",
  null
  
)

export default component.exports